import request from '@/utils/request'

// 查询客户短信模版
export function findAgentsSmsTemplates(params) {
  return request({
    url: `/customer/agents_sms_templates`,
    method: 'get',
    params: params
  })
}

// 查询单个短信模版
export function findAgentsSmsTemplate(id) {
  return request({
    url: `/customer/agents_sms_templates/${id}`,
    method: 'get'
  })
}

// 客户短信模版选项数据
export function findAgentsSmsTemplatesOptions(params) {
  return request({
    url: `/agents_sms_templates/options`,
    method: 'get',
    params: params
  })
}
