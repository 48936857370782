<template>
  <div>
    <a-modal
      width="800px"
      v-model="isShow"
      centered
      :mask-closable="false"
      title="短信模版详情"
    >
      <template slot="footer">
        <a-button class="ant-btn-primary" @click="closeModal">
          关闭
        </a-button>
      </template>

      <a-spin :spinning="spinning">
        <a-row>
          <a-col :span="24">
            <a-descriptions
              title="基本信息"
              :column="1"
              class="iotplt-descriptions iotplt-descriptions-max-6"
            >
              <a-descriptions-item label="模版名称">
                {{ data.name }}
              </a-descriptions-item>

              <a-descriptions-item label="模版内容">
                {{ data.content }}
              </a-descriptions-item>

              <a-descriptions-item label="模版备注">
                {{ data.remark }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
        </a-row>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findAgentsSmsTemplate } from '@/api/agents_sms_template'

export default {
  name: 'ShowAgentsSmsTemplate',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {},
      spinning: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.spinning = true
      findAgentsSmsTemplate(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }

        this.spinning = false
      })
    },

    closeModal() {
      this.isShow = false
    }
  }
}
</script>
